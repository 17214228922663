import ApplicationResource from "@/models/ApplicationResource"

import type AcademicCareer from "@/models/AcademicCareer"
import type AcademicPosition from "@/models/AcademicPosition"
import type AcademicTeaching from "@/models/AcademicTeaching"
import type Address from "@/models/Address"
import type ContactEmailAddress from "@/models/ContactEmailAddress"
import type ContactPhoneNumber from "@/models/ContactPhoneNumber"
import type Form from "@/models/Form"
import type Login from "@/models/Login"
import type ProfessionalCareer from "@/models/ProfessionalCareer"
import type SalutationType from "@/models/SalutationType"
import type SocialMediaLink from "@/models/SocialMediaLink"
import type UserApplicationStatistic from "@/models/UserApplicationStatistic"
import type Tag from "@/models/Tag"

import UserApi from "@/api/user_api"

const API = new UserApi()

export default class User extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = ["avatar"]

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: ResourceAppliedPermission[]
    filled_out_completely?: boolean
    needs_data_confirmation?: boolean
  }

  declare academic_status?: string
  declare academic_status_de?: string
  declare academic_status_en?: string
  declare confirmed?: boolean
  declare confirmed_data_at?: string
  declare contact_email?: string
  declare deactivated?: boolean
  declare engagements?: string[]
  declare first_name?: string
  declare group?: string
  declare last_name?: string
  declare memberships?: string[]
  declare note?: string
  declare preferred_locale?: string
  declare preferred_locale_de?: string
  declare preferred_locale_en?: string
  declare tag_vars?: Record<string, unknown>
  declare title?: string
  declare type_de?: string
  declare type_en?: string
  declare where_do_you_know_us_from?: string

  static readonly associations = {
    academic_careers: {
      type: "many",
    },
    academic_positions: {
      type: "many",
    },
    academic_teachings: {
      type: "many",
    },
    addresses: {
      type: "many",
      sort: (a: Address, b: Address) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    application_statistic: {
      type: "single",
    },
    contact_email_contact: {
      type: "single",
    },
    custom_tags: {
      type: "many",
    },
    email_addresses: {
      type: "many",
    },
    form: {
      type: "single",
    },
    invoice_address: {
      type: "single",
    },
    logins: {
      type: "many",
    },
    phone_numbers: {
      type: "many",
    },
    professional_careers: {
      type: "many",
    },
    salutation_type: {
      type: "single",
    },
    social_media_links: {
      type: "many",
    },
    system_tags: {
      type: "many",
    },
    tags: {
      type: "many",
    },
  }

  declare academic_careers: AcademicCareer[]
  declare academic_positions: AcademicPosition[]
  declare academic_teachings: AcademicTeaching[]
  declare addresses: Address[]
  declare application_statistic?: UserApplicationStatistic
  declare contact_email_contact?: ContactEmailAddress
  declare custom_tags: Tag[]
  declare email_addresses: ContactEmailAddress[]
  declare form?: Form
  declare invoice_address?: Address
  declare logins: Login[]
  declare phone_numbers: ContactPhoneNumber[]
  declare professional_careers: ProfessionalCareer[]
  declare salutation_type?: SalutationType
  declare social_media_links: SocialMediaLink[]
  declare system_tags: Tag[]
  declare tags: Tag[]

  declare name?: string

  localInitialize() {
    if (this.first_name && this.last_name) {
      this.name = `${this.first_name} ${this.last_name}`
    } else if (this.first_name) {
      this.name = this.first_name
    } else if (this.last_name) {
      this.name = this.last_name
    } else {
      this.name = this.contact_email
    }
  }
}
