import ApplicationResource from "./ApplicationResource"
import FormApi from "@/api/form_api"
import type Domain from "@/models/Domain"
import type FormFormInputAssociation from "@/models/FormFormInputAssociation"
import type FormInput from "@/models/FormInput"
import type Poll from "@/models/Poll"

import { classForType } from "@/utils/model_utils"

const API = new FormApi()

export default class Form extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare available_locales?: string[]
  declare hint?: string
  declare hint_de?: string
  declare hint_en?: string
  declare internal_label?: string
  declare label?: string
  declare label_de?: string
  declare label_en?: string
  declare name?: string
  declare purpose?: string
  declare resource_type?: string
  declare system_form?: string

  static readonly associations = {
    domains: {
      type: "many",
    },
    form: {
      type: "single",
    },
    edit_form: {
      type: "single",
    },
    form_inputs: {
      type: "many",
      sort: (a: FormInput, b: FormInput) => (a.order || 0) - (b.order || 0),
    },
    form_form_input_associations: {
      type: "many",
      sort: (a: FormFormInputAssociation, b: FormFormInputAssociation) =>
        (a.order || 0) - (b.order || 0),
    },
    poll_where_custom_form: {
      type: "single",
    },
    sub_forms: {
      type: "many",
    },
  }

  declare domains: Domain[]
  declare edit_form?: Form
  declare form?: Form
  declare form_form_input_associations: FormFormInputAssociation[]
  declare form_inputs: FormInput[]
  declare poll_where_custom_form?: Poll
  declare sub_forms: Form[]

  declare klass?: typeof ApplicationResource

  localInitialize() {
    if (this.resource_type) {
      this.klass = classForType(this.resource_type)
    }
  }

  possibleIncludes(prefix = ""): string[] {
    if (!this.form_inputs) {
      return []
    }

    const includes: string[] = []

    this.form_inputs.forEach((input) => {
      if (input.relationship_only_id) {
        return
      }

      if (input.kind == "sub_form" || input.kind == "relationship") {
        includes.push(`${prefix}${input.name}`)
      }

      if (input.kind == "sub_form" && input.sub_form) {
        includes.push(
          ...input.sub_form.possibleIncludes(`${prefix}${input.name}.`),
        )
      }

      if (input.hidden_if) {
        this.addJmesIncludes(includes, input.hidden_if, prefix)
      }

      if (input.disabled_if) {
        this.addJmesIncludes(includes, input.disabled_if, prefix)
      }
    })

    return includes
  }

  addJmesIncludes(agg: string[], jmes: string, prefix = "") {
    if (!this.klass) {
      return
    }

    const matches = jmes.matchAll(/\.?\b[a-z_]+\b/g)
    for (const match of matches) {
      if (
        !match[0].startsWith(".") &&
        Object.prototype.hasOwnProperty.call(this.klass.associations, match[0])
      ) {
        agg.push(`${prefix}${match[0]}`)
      }
    }
  }

  formInputByName(name: string): FormInput | undefined {
    if (!this.form_inputs) {
      return
    }

    return this.form_inputs.find((fi) => fi.name == name)
  }

  places(): Set<string> {
    return new Set(this.form_inputs.map((input) => input.place))
  }
}
