import ResourceApi from "./resource_api"

import type EventApplication from "@/models/EventApplication"
type curResourceProperties = ResourcePropertiesOf<EventApplication>

export default class EventApplicationApi extends ResourceApi<curResourceProperties> {
  namespace = "event_applications"
  type = "event_application"

  async byAccessToken(token: string): Promise<curResourceProperties> {
    const res = await this.axios.get(
      this.apiPath({ scope: `by_access_token/${token}` }),
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async accept(
    id: string,
    { query }: { query?: unknown } = {},
  ): Promise<curResourceProperties> {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/accept` }),
      {
        type: this.type,
        id,
      },
      { params: query },
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async cancel(
    id: string,
    {
      late_cancel,
      send_mail,
      query,
    }: { late_cancel?: boolean; send_mail?: boolean; query?: unknown } = {},
  ) {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/cancel` }),
      { type: this.type, id, data: { late_cancel, send_mail } },
      { params: query },
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async requestLateCancel(id: string, { query }: { query?: unknown } = {}) {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/request_late_cancel` }),
      { type: this.type, id },
      { params: query },
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async handIn(
    id: string,
    {
      query,
      confirmationToken,
    }: { query?: unknown; confirmationToken?: string } = {},
  ) {
    const body: Record<string, unknown> = {
      type: this.type,
      id,
    }

    if (confirmationToken) {
      body.data = { confirmation_token: confirmationToken }
    }

    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/hand_in` }),
      body,
      { params: query },
    )

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  requestConfirmationToken(id: string, { query }: { query?: unknown } = {}) {
    return this.axios.put(
      this.apiPath({ scope: `${id}/request_confirmation_token` }),
      {
        type: this.type,
        id,
      },
      { params: query },
    )
  }

  async putOnWaitingList(id: string, { query }: { query?: unknown } = {}) {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/put_on_waiting_list` }),
      {
        type: this.type,
        id,
      },
      { params: query },
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async putToDecide(id: string, { query }: { query?: unknown } = {}) {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/put_to_decide` }),
      {
        type: this.type,
        id,
      },
      { params: query },
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async markPaid(
    id: string,
    { query }: { query?: unknown } = {},
  ): Promise<curResourceProperties> {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/mark_paid` }),
      {
        type: this.type,
        id,
      },
      { params: query },
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async markUnpaid(
    id: string,
    { query }: { query?: unknown } = {},
  ): Promise<curResourceProperties> {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/mark_unpaid` }),
      {
        type: this.type,
        id,
      },
      { params: query },
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async reject(id: string, { query }: { query?: unknown } = {}) {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/reject` }),
      {
        type: this.type,
        id,
      },
      { params: query },
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async moveOnWaitingList(
    resource_to_move_id: string,
    position: number,
    {
      query,
      relationshipPath,
    }: {
      query?: { [x: string]: any }
      relationshipPath?: Array<{ id?: string; name: string }>
    } = {},
  ): Promise<{ order: string[] }> {
    const path = this.apiPath({
      scope: `${resource_to_move_id}/move_on_waiting_list`,
    })

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    const res = await this.axios.put(
      path,
      {
        data: {
          id: resource_to_move_id,
          type: this.type,
          position,
        },
      },
      { params: query },
    )

    return res.data.data
  }

  invite(filter: unknown) {
    const path = this.apiPath({ scope: `/invite` })
    return this.axios.put(path, { data: { filter } })
  }

  markAllPaid(filter: unknown) {
    const path = this.apiPath({ scope: `/mark_paid` })
    return this.axios.put(path, { filter })
  }

  markAllUnpaid(filter: unknown) {
    const path = this.apiPath({ scope: `/mark_unpaid` })
    return this.axios.put(path, { filter })
  }

  async move(id: string, new_event_id: string) {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/move` }), {
      type: this.type,
      id,
      data: { event_id: new_event_id },
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async downloadCertificate(
    id: string,
    { query }: { query?: Record<string, unknown> } = {},
  ): Promise<void> {
    window.open(
      this.apiUrl({
        scope: `${id}/certificate`,
        format: "pdf",
        params: query,
      }),
    )
  }

  async downloadInvoice(id: string): Promise<void> {
    window.open(this.apiUrl({ scope: `${id}/invoice`, format: "pdf" }))
  }

  syncInvoice(id: string) {
    const path = this.apiPath({ scope: `${id}/sync_invoice` })
    return this.axios.put(path, {
      type: this.type,
      id,
    })
  }
}
